import { Modal, Card, Typography, Box, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../_utils/modalStyle";
import ShareThirdList from "../../pages/third_party/share/ShareThirdList";
import ShareThirdFolder from "../../pages/third_party/share/ShareThirdFolder";
import axios from "axios";
import Stepper from "../_third_party/Stepper";
import CustomButtonModal from "../common/CustomBtnModal";
import { Player } from "@lottiefiles/react-lottie-player";
import { toast } from "react-toastify";
import { toastError } from "../../_utils/toastError";
import { useSelector } from "react-redux";

const BodyRoot = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Root = styled("div")`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Body = styled("div")`
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  > .body-container {
    width: 100%;
    height: 100%;
  }
`;

const ShareWithThirdModal: React.FC<any> = ({
  open,
  close,
  onFinish,
  profiles,
  onAddThirdParties,
  setSelectedBenef,
}) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedThirdParty, setSelectedThirdParty] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const context = useSelector((state: any) => state.auth.context);

  useEffect(() => {
    setStep(0);
    setMaxStep(0);
    setSelectedId("");
    setSelectedFolder("");
    setSelectedThirdParty(null);
    setLoading(false);
  }, [open]);

  const nextStep = async () => {
    if (selectedId.length > 0 && step < steps.length - 1) {
      setStep((current) => current + 1);
      setMaxStep(1);
    }

    if (step === steps.length - 1) {
      try {
        setLoading(true);
        const profilesIds = profiles.map(
          (el: any) => el?.candidate?.id ?? el?.person?.id ?? el?.id
        );
        const body = {
          profiles: profilesIds.map((id: string) => ({
            candidate: `api/people/${id}`,
            reference: null,
            thirdParty: `api/third_parties/${selectedId}`,
            ...(selectedFolder && {
              campaigns: [
                `/api/third_parties/${selectedId}/campaigns/${selectedFolder}`,
              ],
            }),
          })),
        };

        await axios.post("profiles/multiple", body);

        toast.success(
          t("third_party.campaign.shared", { context, count: profiles.length })
        );

        if (onAddThirdParties && selectedThirdParty) {
          console.log("selectedThirdParty", selectedThirdParty);
          onAddThirdParties(selectedThirdParty);
        }
        setSelectedBenef && setSelectedBenef([]);
      } catch (err) {
        toastError();
      } finally {
        close();
        onFinish();
      }
    }
  };

  const steps = [
    <ShareThirdList
      onSelect={setSelectedId}
      selected={selectedId}
      onLoad={setLoading}
      itemsPerPage={7}
      setSelectedThirdParty={setSelectedThirdParty}
    />,
    <ShareThirdFolder
      onSelect={setSelectedFolder}
      selected={selectedFolder}
      onLoad={setLoading}
      thirdId={selectedId}
      itemsPerPage={7}
    />,
  ];

  const stepsLabels = [
    t("third_party.label"),
    t("third_party.campaign.label_upper"),
  ];

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
          width: { xs: "95%", lg: "1200px" },
          height: "600px",
        }}
      >
        <BodyRoot>
          <Root>
            <Stepper
              step={step}
              steps={stepsLabels}
              setStep={setStep}
              maxStep={maxStep}
            />
            <Body>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ height: "90px" }}>
                  <Header count={profiles.length} />
                </Box>

                <Box sx={{ mt: 2 }}>{steps[step]}</Box>

                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="center"
                  sx={{ pt: 1, marginTop: "auto" }}
                >
                  <CustomButtonModal
                    secondary
                    type="button"
                    onClick={close}
                    variant="outlined"
                    color="secondary"
                    text={t(`btn.cancel`)}
                  />
                  <CustomButtonModal
                    onClick={nextStep}
                    variant="contained"
                    text={t(`btn.next_step`)}
                    disabled={loading}
                  />
                </Box>
              </Box>
            </Body>
          </Root>
        </BodyRoot>
      </Card>
    </Modal>
  );
};

export default ShareWithThirdModal;

const BoxIcon = styled(Box)(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.green[500],
}));

const Header: React.FC<any> = ({ count }) => {
  const { t }: any = useTranslation("common");
  const context = useSelector((state: any) => state.auth.context);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ mt: { sm: -4 }, mb: 2 }}
    >
      <BoxIcon>
        {mounted && (
          <Player
            loop
            autoplay
            src="/static/illustrations/lotties/benef.json"
            style={{ height: "35px", width: "35px" }}
          ></Player>
        )}
      </BoxIcon>

      <Box display="flex" alignItems="center">
        <Typography variant="h4" textAlign="center">
          {t("third_party.campaign.share", { context, count })}
        </Typography>
      </Box>
    </Box>
  );
};
