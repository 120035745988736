import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const ColorBar = styled(Box)(({ theme }: any) => ({
  height: "7px",
  borderRadius: "3px",
  margin: theme.spacing(0.5),
  position: "relative",
}));

const NumberBar = styled(Typography)(({ theme }: any) => ({
  color: "#B5B5B5",
  position: "absolute",
  top: "-25px",
}));

const IndicatorsBar = styled(Box)(({ theme }: any) => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: "3px solid",
  backgroundColor: "white",
  position: "absolute",
}));

const IncProgress: React.FC<{
  value: number;
}> = ({ value }) => {
  const { t } = useTranslation("common");

  const returnColor = () => {
    return value < 25
      ? "#E76262"
      : value >= 25 && value < 50
      ? "#ECA336"
      : value >= 50 && value < 75
      ? "#F9CF4A"
      : "#3ECE80";
  };

  const chartParams: any = {
    options: {
      stroke: {
        width: 6,
      },
      fill: {
        pattern: {
          style: "donut",
        },
      },
      colors: ["#E76262", "#ECA336", "#F9CF4A", "#3ECE80"],
      dataLabels: { enabled: false },
      legend: { show: false },
      tooltip: { enabled: false },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          donut: {
            size: "85%",
            labels: {
              show: true,
              name: {
                show: false,
              },
              total: {
                show: true,
                fontSize: "45px",
                fontFamily: "Plus Jakarta Sans",
                color: "#404040",
                formatter: (e: any) => `${value}%`,
              },
              value: {
                show: true,
                fontSize: "45px",
                fontFamily: "Plus Jakarta Sans",
                color: "#404040",
                offsetY: -30,
                formatter: (e: any) => `${value}%`,
              },
            },
          },
        },
      },
    },
  };
  const chartParams2: any = {
    options: {
      stroke: {
        width: 4,
        colors: ["rgba(0,0,0,0)", returnColor(), "rgba(0,0,0,0)"],
        lineCap: "round",
      },
      fill: {
        pattern: {
          style: "donut",
        },
      },
      colors: ["rgba(0,0,0,0)", "#fff", "rgba(0,0,0,0)"],
      dataLabels: { enabled: false },
      legend: { show: false },
      tooltip: { enabled: false },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          donut: {
            size: "85%",
            labels: {
              show: false,
            },
          },
        },
      },
    },
  };

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
    },
    series: [50, 12.5, 15, 22.5],
  };

  const chart2 = {
    ...chartParams2,
    options: {
      ...chartParams2.options,
    },
    series: [value, 3, 100 - (value + 3)],
  };

  return (
    <>
      <Box display="flex" justifyContent="center" width="100%">
        <ReactApexChart
          series={chart.series}
          options={chart.options}
          chart={chart.chart}
          height={300}
          type="donut"
        />
      </Box>
      <Box sx={{ zIndex: 10, marginTop: "-270px" }}>
        <ReactApexChart
          series={chart2.series}
          options={chart2.options}
          chart={chart2.chart}
          height={300}
          type="donut"
        />
      </Box>
      <Typography
        textAlign="center"
        variant="subtitle1"
        sx={{ color: returnColor(), mt: -18 }}
      >
        {t(
          `inc.matching.${
            value < 50
              ? "very_low"
              : value >= 50 && value < 60
              ? "low"
              : value >= 60 && value < 75
              ? "mid"
              : "excellent"
          }`
        )}
      </Typography>
      <Typography textAlign="center" variant="body1" sx={{ color: "#B5B5B5" }}>
        {t("inc.matching.matching_subtitle")}
      </Typography>
      <Box display="flex" justifyContent="center" sx={{ mt: 4, mb: 6 }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: { xs: "300px", sm: "400px" }, position: "relative" }}
        >
          <ColorBar
            sx={{
              width: "50%",
              backgroundColor: "#E76262",
            }}
          >
            <NumberBar variant="body1">0</NumberBar>
          </ColorBar>
          <ColorBar
            sx={{
              width: "12.5%",
              backgroundColor: "#ECA336",
            }}
          >
            <NumberBar variant="body1">50</NumberBar>
          </ColorBar>
          <ColorBar
            sx={{
              width: "15%",
              backgroundColor: "#F9CF4A",
            }}
          >
            <NumberBar variant="body1">60</NumberBar>
          </ColorBar>
          <ColorBar
            sx={{
              width: "22.5%",
              backgroundColor: "#3ECE80",
            }}
          >
            <NumberBar variant="body1">75</NumberBar>
            <NumberBar
              variant="body1"
              sx={{
                right: "0px",
              }}
            >
              100
            </NumberBar>
          </ColorBar>
          <IndicatorsBar
            sx={{ borderColor: returnColor(), left: `${value - 1}%` }}
          />
        </Box>
      </Box>
    </>
  );
};

export default IncProgress;
