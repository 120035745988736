import { Collapse, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ReactApexChart from "react-apexcharts";

const ColumnChart: React.FC<{
  data?: number[];
  labels?: string[];
  height?: number;
  type: string;
}> = ({ data, labels, height = 200, type }) => {
  console.log(type);

  const typeLabel = type === "connection" ? "connexions" : "vues";

  const chartParams: any = {
    options: {
      plotOptions: {
        bar: {
          borderRadius: 8,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
          return `<div style="padding: 6px;">
          <span>Nombre de ${typeLabel} :  <b>${series[seriesIndex][dataPointIndex]}</b></span></div>`;
        },
      },
      xaxis: {
        categories: labels,
        labels: { style: { fontFamily: "Plus Jakarta Sans" } },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    },
  };

  const chart = {
    ...chartParams,
    options: {
      ...chartParams.options,
    },
    series: [
      {
        data: data,
      },
    ],
  };
  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      <Zoom in={true}>
        <Box>
          <ReactApexChart
            series={chart.series}
            options={chart.options}
            chart={chart.chart}
            height={height}
            type="bar"
          />
        </Box>
      </Zoom>
    </Collapse>
  );
};

export default ColumnChart;
