import { Typography, Box, styled } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";

const ImageContainer = styled(Box)({
  borderRadius: "35px",
  width: "80px",
  height: "80px",
  minHeight: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const DescriptionText = styled(Typography)({
  fontWeight: 400,
  color: "#333539",
  fontFamily: "Plus Jakarta Sans light,Plus Jakarta Sans light,sans-serif",
  lineHeight: "17px",
  opacity: "0.8",
  maxWidth: "21ch",
});

const CompatibilityIcon: any = styled(CheckCircleIcon, {
  shouldForwardProp: (props) => props !== "compatible",
})(({ theme, compatible }: any) => ({
  color: compatible ? theme.palette.green[500] : "#F58A1E",
}));

const CompatibilityText: any = styled(Typography, {
  shouldForwardProp: (props) => props !== "compatible" && props !== "difficult",
})(({ theme, compatible, difficult }: any) => ({
  color: compatible
    ? theme.palette.green[500]
    : difficult
    ? "#F58A1E"
    : "#FF1249",
}));

const IncIrritantsListItem: React.FC<any> = ({ el, color, bgColor }) => {
  const { t } = useTranslation("common");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        width: "200px",
        height: el?.color ? "240px" : "230px",
        mb: el?.color ? 4 : 0,
      }}
    >
      <ImageContainer
        sx={{
          background:
            bgColor === "red"
              ? "#FCE4E8"
              : bgColor === "orange"
              ? "#FEEEDF"
              : "#E2F7E2",
        }}
      >
        <img
          style={{ width: "45px" }}
          alt="curisity icon"
          src={`/static/icons/Icons-irittants/${el.var}.svg`}
        />
      </ImageContainer>
      <Typography
        sx={{ lineHeight: "18px", mb: 1, mt: 2 }}
        variant="subtitle1"
        textAlign="center"
      >
        {el?.texts?.title || el?.title || el?.description}
      </Typography>
      <DescriptionText
        sx={{
          mb: 2,
        }}
        variant="body1"
        textAlign="center"
      >
        {el?.texts?.text || el?.text}
      </DescriptionText>
      {el.color && (
        <Box
          marginTop={"auto"}
          display="flex"
          alignItems="center"
          sx={{
            background:
              color === "green"
                ? "#ECFCEC"
                : color === "orange"
                ? "#FEEEDF"
                : "#FFEFF1",
            padding: "10px",
            borderRadius: "9px",
          }}
        >
          {color === "green" || color === "orange" ? (
            <CompatibilityIcon
              compatible={color === "green"}
              sx={{ fontSize: "12px", mr: "5px" }}
            />
          ) : (
            <CancelRoundedIcon
              sx={{ color: "#FF1249", fontSize: "12px", mr: "5px" }}
            />
          )}
          <CompatibilityText
            compatible={color === "green"}
            difficult={color === "orange"}
            variant="body2"
          >
            {color === "green"
              ? t("inc.irritant.compatible")
              : color === "orange"
              ? t("inc.irritant.difficult")
              : t("inc.irritant.uncompatible")}
          </CompatibilityText>
        </Box>
      )}
    </Box>
  );
};

export default IncIrritantsListItem;
