import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LastActionCard from "../components/last_action/LastActionCard";
import LogoLoader from "../components/LogoLoader";
import Page from "../components/Page";
// import ProfileAside from "../components/profile/ProfileAside";
import ProfileContent from "../components/profile/ProfileContent";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileListCampaigns from "../components/profile/ProfileListCampaigns";
import ProfileOtherInfos from "../components/profile/ProfileOtherInfos";
import ProfileProgression from "../components/_collab/home/ProfileProgression";
import { useSelector } from "react-redux";
import { ProfileContext } from "../contexts/ProfileContext";
import SideContentLayout from "../layout/SideContentLayout";
import peopleAPI from "../_api/_people";
import FaceIcon from "@mui/icons-material/Face";
import MatchingCandidateProfile from "../components/folder/matching/MatchingCandidateProfile";
import useFetch from "../hooks/useFetch";
import WorkIcon from "../icons/menu/work";
import ProfileTopSoftSkills from "../components/profile/ProfileTopSoftSkills";
import ProfileArchetypes from "../components/profile/ProfileArchetypes";
import TagCard from "../components/profile/TagCard";
import TestsAPI from "../_api/_testsAPI";
import ShareModal from "./third_party/share/ShareModal";
import profileFormatter from "../_utils/formatters/profileFormatter";
import thirdPartiesAPI from "../_api/_thirdPartiesAPI";
import DeanonymizationButton from "../components/_third_party/profile/DeanonymizationButton";
import ThirdProfileList from "../components/profile/OtherInfos/ThirdProfileList";
import NoShareButton from "./third_party/share/NoShareButton";
import AskShareButton from "./third_party/share/AskShareButton";

const ProfilePage: React.FC = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { peopleResponse, peopleData } = useContext(ProfileContext);

  const context = useSelector((state: any) => state.auth.context);
  const tokenData = useSelector((state: any) => state.user);
  const menu = useSelector((state: any) => state.menu);
  const hasThirdMenu = useSelector((state: any) => state.menu.third_party);
  const isThird = context === "third_party";
  const thirdId = tokenData.thirdParty_id;
  const anonymous = peopleData?.anonymous;
  const sharePartners = peopleData?.otherData?.sharePartners;

  const isRhOrAdvisor =
    context === "advisor" || context === "rh" || context === "manager";
  const hasThird =
    hasThirdMenu && (context === "advisor" || context === "recruiter");

  const canShareProfile =
    (context === "advisor" || context === "recruiter") && menu.third_party;

  const { id, profileId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const [openMatchingModal, setOpenMatchingModal] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<any>();
  const [testData, setTestData] = useState<any>();
  const [tabChange, setTabChange] = useState<string>("");
  const [completenessData, setCompletenessData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thirdParties, setThirdParties] = useState<any>();

  const type = "person";
  const testsData = useFetch(
    TestsAPI.get({ "person.id": id ?? tokenData?.person_id })
  );

  useEffect(() => {
    setTestData(testsData.data);
    setIsLoading(testsData.loading);
  }, [testsData]);

  //! Temporary
  const show = localStorage.getItem("lng") === "fr";

  const getCompleteness = async (id: string) => {
    setIsLoading(true);
    try {
      const completenessRes = await axios.get(
        peopleAPI.getById(`${id}/completeness`)
      );
      setCompletenessData(completenessRes.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const getEmployeeData = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        !isThird
          ? peopleAPI.getById(id)
          : thirdPartiesAPI.getById(`${thirdId}/profiles/${profileId}`)
      );
      setEmployeeData(profileFormatter(res.data));

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const handleAddThirdParty = (newThirdParty: any) => {
    setThirdParties((prevThirdParties: any) => [
      ...prevThirdParties,
      newThirdParty,
    ]);
  };

  const { data, loading } = useFetch(
    peopleAPI.getById(`${id ?? tokenData?.person_id}/completeness`)
  );

  const returnNotCollabContext = () => {
    return (
      context !== "collab" && context !== "benef" && context !== "candidate"
    );
  };

  useEffect(() => {
    getCompleteness(id ?? tokenData?.person_id);
    // getTestsData(id ?? tokenData?.person_id);
    id && getEmployeeData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tokenData.person_id, profileId]);

  const fullName = `${
    employeeData
      ? employeeData.firstName
      : peopleResponse?.data?.firstName ?? ""
  } ${
    employeeData ? employeeData.lastName : peopleResponse?.data?.lastName ?? ""
  }`;

  useEffect(() => {
    if (peopleResponse.error?.status === 404) {
      console.log("PROFILERESPONSE ERROR REDIRECT", peopleResponse.error);
      navigate("/404", { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleResponse?.error]);

  useEffect(() => {
    setThirdParties(employeeData?.thirdParties);
  }, [employeeData]);

  return (
    <Page title={`${t("profile")} | ${fullName}`}>
      <LogoLoader open={peopleResponse.loading || isLoading || loading} />
      {((!peopleResponse.loading && peopleData && !id) ||
        (id && employeeData && !isLoading && !loading)) && (
        <>
          <SideContentLayout
            main={
              <>
                <ProfileHeader
                  notCollab={returnNotCollabContext()}
                  profileData={employeeData ? employeeData : peopleData}
                  completeness={id ? completenessData : data}
                  tabChange={setTabChange}
                />
                <ProfileContent
                  employeeData={employeeData}
                  testData={testData}
                  notCollab={returnNotCollabContext()}
                  context={context}
                  loading={isLoading}
                  tabChange={tabChange}
                  candidateId={queryParams?.get("candidateId") ?? ""}
                />
              </>
            }
            side={
              <Stack spacing={2}>
                {/* <ProfileAside /> */}
                {returnNotCollabContext() &&
                  !isThird &&
                  (isRhOrAdvisor ? (
                    <Button
                      onClick={() =>
                        navigate(`/app/profile/${id}/work-suggest`)
                      }
                      variant="contained"
                      sx={{ width: "100%", height: "50px" }}
                    >
                      <WorkIcon size={18} />
                      <Typography sx={{ ml: 1 }}>
                        {t("profilepage.see_work_matching")}
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setOpenMatchingModal(true)}
                      variant="contained"
                      sx={{ width: "100%", height: "50px" }}
                    >
                      <FaceIcon />
                      <Typography sx={{ ml: 2 }}>
                        {t("profilepage.see_campaign_matching")}
                      </Typography>
                    </Button>
                  ))}

                {canShareProfile ? (
                  sharePartners ? (
                    <ShareModal
                      id={id!}
                      onAddThirdParties={handleAddThirdParty}
                    />
                  ) : (
                    <>
                      <NoShareButton />
                      <AskShareButton id={id!} />
                    </>
                  )
                ) : null}

                {hasThird && sharePartners && thirdParties && (
                  <ThirdProfileList thirdParties={thirdParties} />
                )}

                {(context === "rh" || context === "manager") && (
                  <Button
                    onClick={() => navigate(`/app/profile/${id}/succession`)}
                    variant="contained"
                    sx={{ width: "100%", height: "50px" }}
                  >
                    <Typography sx={{ ml: 2 }}>Plan de succession</Typography>
                  </Button>
                )}

                {(context === "rh" || context === "manager") && (
                  <Button
                    onClick={() => navigate(`/app/profile/${id}/projects`)}
                    variant="contained"
                    sx={{ width: "100%", height: "50px" }}
                  >
                    <Typography sx={{ ml: 2 }}>Projets</Typography>
                  </Button>
                )}
                {returnNotCollabContext() && !anonymous && (
                  <TagCard personId={id} type={type} needModal={false} />
                )}

                {isThird && anonymous && (
                  <DeanonymizationButton person={peopleData} />
                )}

                {(context === "advisor" || context === "recruiter") && (
                  <ProfileListCampaigns
                    person={peopleResponse?.data}
                    personId={id}
                    context={context}
                    candidateId={queryParams?.get("candidateId") ?? ""}
                  />
                )}

                {(id ? completenessData : data)?.completenessOfProfile
                  ?.hasDynamic && (
                  <ProfileTopSoftSkills
                    context={context}
                    completeness={
                      (id ? completenessData : data)?.completenessOfProfile
                    }
                    data={testData?.["hydra:member"]}
                  />
                )}
                {(id ? completenessData : data)?.completenessOfProfile
                  ?.hasBigFive &&
                  show && <ProfileArchetypes />}

                {(context === "recruiter" || context === "advisor") && (
                  <ProfileProgression
                    personId={id!}
                    completeness={id ? completenessData : data}
                    profile
                  />
                )}
                {!anonymous && (
                  <ProfileOtherInfos notCollab={returnNotCollabContext()} />
                )}
                {show && !anonymous && <LastActionCard personId={id} />}
              </Stack>
            }
          />
        </>
      )}
      <MatchingCandidateProfile
        open={openMatchingModal}
        close={() => setOpenMatchingModal(false)}
        person={peopleResponse?.data}
      />
    </Page>
  );
};
export default ProfilePage;
