import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import ReplyIcon from "@mui/icons-material/Reply";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const FeedbackPeBody: React.FC<{ firstConnection?: boolean }> = ({
  firstConnection,
}) => {
  const tokenData = useSelector((state: any) => state.user, shallowEqual);
  const { t } = useTranslation("common");

  const redirectPe = () => {
    window.open(
      "https://www.emploi-store.fr/portail/services/guideDesMetiers",
      "_blank"
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        src="/static/illustrations/feedback.svg"
        alt="feedback illu"
        width="200"
      />
      <Typography
        textAlign="center"
        sx={{ fontFamily: "Plus Jakarta Sans", mt: 2 }}
        variant="h4"
      >
        {firstConnection
          ? t("feedback.2step.firstConnection")
          : t("feedback.2step.text1", {
              name: tokenData.firstName,
            })}
      </Typography>
      <Typography
        textAlign="center"
        sx={{ maxWidth: "38ch", mt: 1, lineHeight: 1.2 }}
        variant="body1"
      >
        {t("feedback.2step.text2")}
      </Typography>
      <Typography
        textAlign="center"
        variant="h5"
        sx={{ color: "#7F4996", mb: 0, mt: 3, lineHeight: 1 }}
      >
        {t("feedback.2step.text3")}
      </Typography>
      <Typography
        textAlign="center"
        variant="h5"
        sx={{ color: "#004282", mb: 3 }}
      >
        {t("feedback.2step.text4")}
      </Typography>
      <img
        src="/static/images/pe_store.png"
        alt="emploi store illu"
        width="175"
      />
      <Button
        sx={{
          mt: 4,
          borderRadius: "40px",
          backgroundColor: "#004282",
          display: "flex",
          alignItems: "center",
          p: 2,
          pr: 3,
          pl: 3,
        }}
        onClick={redirectPe}
        variant="contained"
      >
        <Typography variant="body1" sx={{ mr: 1 }}>
          {t("feedback.2step.btn")}
        </Typography>
        <ReplyIcon sx={{ transform: "rotateY(180deg)" }} />
      </Button>
    </Box>
  );
};

export default FeedbackPeBody;
